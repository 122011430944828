import React, { Component } from "react";
import {     Breadcrumb,  Grid,
  Row,
  Col,
FormControl,
Form,
FormGroup,
ControlLabel,
  Modal,
  Tooltip,
  OverlayTrigger
  } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Loading from "components/Loader/loading.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-table-6/react-table.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconImage from "components/Icon/icon.jsx";
const Config = require('config');


class Addresses extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                addressesdatalist:[],
                from: undefined,
                to: undefined,
                showAddressModal:false,
                isSubmitted:false,
                setSelectedOption:'',
                selectData:[],
                refid:'',
                address:'',
                addressStep:1,
                tag:'',
                showAddressLoading:false,
                refidError:null,
                setSelectedOptionError:null,
                copied: false,
                copiedtag:false,
                fulldata:'',
                showError:false,
                kyc:false
                }
                this.handleFromChange = this.handleFromChange.bind(this);
                this.handleToChange = this.handleToChange.bind(this);
                this.handleChange = this.handleChange.bind(this);
                this.handleCoinChange = this.handleCoinChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
              //  console.log('this.props', this.props)
            }
    
   
            showFromMonth() {
              const { from, to } = this.state;
              if (!from) {
                return;
              }
            if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
            }
            }
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
         
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }
            
componentWillMount(){

  var user=JSON.parse(localStorage.getItem('user'));
  var selectData=JSON.parse(localStorage.getItem('assets'));
  this.setState({selectData:selectData},this.getaddresses())

  if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
    this.setState({kyc:false})}
    

  }
  export(){ 
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:'Addresses',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['ref id','id','coin','date time','wallet address','tag']
      };
     
   
    const csvExporter = new ExportToCsv(options);
   
      

csvExporter.generateCsv(this.state.addressesdatalist)


  }
  getaddresses(){
var startDate=this.state.from;
var endDate=this.state.to;

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid},
      body:JSON.stringify({startDate,endDate})
   };
      


    fetch(Config.API+'/api/addresses',requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ addressesdatalist:transactiondata.data,loading:false}));
   

  }

 

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


handleCreateResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          
       // const error = (data && data.message);
        return 'error'
      }else{

        if(data.status=='error'){
       
             return 'error'

        }
      

      }
     
    
    return data;
  });
}




create(){

  this.setState({
    showAddressModal:true,setSelectedOption:'', refidError:null, setSelectedOptionError:null,refid:'',addressStep:1,address:'', tag:'',copied:false,copiedtag:false,showError:false
    })
}


handleCoinChange = (e) => {
 // this.setState({setSelectedOption:e},()=>{this.loadcoin(this.props,this.state.setSelectedOption.value,this.state.setSelectedOption.text,this.state.setSelectedOption.logo)})
 this.setState({setSelectedOption:e},()=>{this.handleValidate()})
 
}


handleChange(e) {
  const { name, value } = e.target;
  this.setState({ [name]: value })

}

handleValidate(e) {

  this.state.refid==''
 ? this.setState({
   refidError: (
 <small className="text-danger">A Unique Reference ID is required</small>
 )
 })

   : this.setState({  refidError: null });



   this.state.setSelectedOption==''
   ? this.setState({
    setSelectedOptionError: (
   <small className="text-danger">A Coin must be selected</small>
   )
   })
  
     : this.setState({  setSelectedOptionError: null });

     return

 }
 createaddress(ref_id,asset){
  this.setState({addressStep:2,showAddressLoading:true})
  var user=JSON.parse(localStorage.getItem('user'));
  var mid=user.data.merchant_id;
var token=user.token;
var asset=asset
var ref_id=ref_id
const requestOptions = {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'merchant_id':mid},
body: JSON.stringify({ref_id})
};

if(asset=='USDT' || asset=='USDC' || asset=='SHIB' || asset=='MLP' || asset=='TSUKA'){
  return fetch(Config.API+'/api/address/erc20/create/'+asset,requestOptions)
.then( this.handleResponse)
.then( api=> {if (api=='error'){  this.setState({showError:true,showAddressLoading:false}) }else{this.setState({address:api.data.address,
  tag:api.data.tag,showAddressLoading:false,loading:false})}})
}else{
return fetch(Config.API+'/api/address/create/'+asset,requestOptions)
.then( this.handleCreateResponse)
.then( api=> {if (api=='error'){  this.setState({showError:true,showAddressLoading:false}) }else{
  this.setState({fulldata:api,address:api.data.address,
    tag:api.data.tag,showAddressLoading:false})
}}
  

  
  )

}
 }


   async handleSubmit(e) {

  await this.handleValidate();

if(this.state.setSelectedOptionError===null && this.state.refidError===null){

  this.createaddress(this.state.refid,this.state.setSelectedOption.asset_id)
}

 }


 
  render() {
    const { from, to,isSubmitted,showAddressModal,setSelectedOption,selectData,refid,refidError,addressStep,kyc} = this.state;
var data=selectData.data
function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return (
      row[id] !== undefined ?
      isNaN(row[id])
        ?  String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : String(row[id]).startsWith(filter.value)
      :
          true
  );
}

  const customStyles = {
    menuList: () => ({
     height: '200px',
     overflow:'scroll'
    })
  }
    const modifiers = { start: from, end: to };
    const FORMAT = 'YYYY-MM-DD';
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>   <h3 className="header">Wallet Addresses</h3></Col>
<Col lg={12}>

          <div className="card">
      
             

          <div className="content">
         
         <div className="options">  {kyc==true
          ?<span><Button  bsStyle="default" onClick={() => this.create()}>
                       Generate New Address
                        <span className="btn-label btn-label-right">
                           <i className="fa fa-wallet " />
                        </span>
                      </Button>
                      <Button  bsStyle="default" onClick={() => this.export()}>
                       Export
                        <span className="btn-label btn-label-right">
                           <i className="fa fa-download " />
                        </span>
                      </Button></span>
                      :''}


            <div className="DateSelector">   <div className="InputFromTo">
        <DayPickerInput
          value={formatDate(new Date(),'YYYY-MM-DD')}
          placeholder="From"

          format={FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={formatDate(new Date(),'YYYY-MM-DD')}

            placeholder="To"
            format={FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
             <Button  bsStyle="default" onClick={() => this.getaddresses()}>
                       Go
                      
                      </Button>
        </span>
          
        
         
       
        
        </div></div></div>
      
                  <ReactTable
                  
                    data={this.state.addressesdatalist}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                    columns={[
                      
                     {
                     
                        Header: "Coin",
                
  
  
                        accessor: "coin",
                       Cell: row => {  if (row.value) {return <div alt={row.value}> <IconImage  coin={row.value} />{row.value}</div>}else{return <div></div>}},
                    
                      
                        
                      
  
                      
                      }, {
                        Header: "Address",
                        accessor: "address"
                      }
                     
                      ,
                      {
                        Header: "Tag",
                        accessor: "tag"
                      }
                     
                      ,
                      {
                        Header: "Ref/ Invoice ID",
                        accessor: "ref_id"
                      },
                      {
                        Header: "ID",
                        accessor: "wid"
                      },
                      {
                        Header: "Date",
                        accessor:"date",
                       
                        Cell: props => {
                          return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                        },
                      }
                      
                      
                    ]}
                    noDataText= "No Addresses Found"
                    defaultPageSize={10}
              
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                

               
          </div>
          </div></Col>
          </Row>
        </Grid>


        

        <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showAddressModal}
                      onHide={() => this.setState({ showAddressModal: false  },this.getaddresses())}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>New Address</Modal.Title>
                      </Modal.Header> 

                      {addressStep==1
                      ?<div>
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
       
         <Form horizontal onSubmit={this.handleSubmit}>

         <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={3} smOffset={0}>
               Reference ID <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
  Unique ID to track transactions
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col  sm={6}>
           
                         <FormControl
                            type="text"
                            name="refid"
                          value={refid}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                     

                    
                      
                        </Col><Col sm={6} smOffset={0}>{this.state.refidError}</Col>
                        </FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col sm={12}>

{data.map(e => {
              return (
                <Col sm={4} style={{padding:'0px' }}  >{ e.asset_id==setSelectedOption.asset_id ?<div className='coinselect selected' onClick={() => this.handleCoinChange(e)}style={{ display: 'flex', alignItems: 'center',height:'40px',cursor:'pointer',borderRadius:'5px'}}>
                <img src={e.icon_url} style={{ height:'20px' }}/>
                <span style={{ marginLeft: 5 }}>{e.name}</span>
              </div>
              
              :<div className='coinselect' onClick={() => this.handleCoinChange(e)}style={{ display: 'flex', alignItems: 'center',height:'40px',cursor:'pointer',borderRadius:'5px'}}>
                <img src={e.icon_url} style={{ height:'20px' }}/>
                <span style={{ marginLeft: 5 }}>{e.name}</span>
              </div>}
              
              </Col>
              );
            })}



      
  </Col>
  <Col  sm={6} smOffset={0}>{this.state.setSelectedOptionError}</Col>

</FormGroup></Form>
         
         </Col></Row></Grid></Modal.Body>
         <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showAddressModal: false })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmit()}
                         
                        >Submit
                        </Button>
                      </Modal.Footer></div>
                      :<div>
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
        {this.state.showAddressLoading ?<div className='addressLoader' style={{ display: 'flex', alignItems: 'center',height:'100px'}}><Loader></Loader></div>: this.state.showError
          ?<div><Form horizontal> <FormGroup ><Col   sm={11} smOffset={1}  >
            There was an issue creating the wallet address.<br/>Please try again.
            </Col></FormGroup></Form></div>
         
       :<div><Form horizontal> <FormGroup >
         <Col  componentClass={ControlLabel} sm={3} smOffset={1}  >Coin</Col>  <Col  sm={7} >   <img src={setSelectedOption.icon_url} style={{ height:'20px' }}/> {setSelectedOption.name}</Col></FormGroup>
         <FormGroup >  <Col  componentClass={ControlLabel} sm={3} smOffset={1}  >Reference ID</Col>  <Col  sm={8} >{this.state.refid}</Col></FormGroup>
         <FormGroup >  <Col componentClass={ControlLabel} sm={3} smOffset={1}  >Address</Col>  <Col  sm={8} ><div className="addresstag">{this.state.address}</div> </Col></FormGroup>
         <FormGroup ><Col componentClass={ControlLabel}  sm={3} smOffset={1}  ></Col>  <Col  sm={8} > <CopyToClipboard text={this.state.address}
          onCopy={() => this.setState({copied: true,copiedtag: false})}>
          <a >{this.state.copied ? <span  style={{color: 'green'}}>Address has been copied</span> : <span className="btn">Copy Address</span>}</a>
        </CopyToClipboard>
         </Col></FormGroup>
       {this.state.tag!='' ?<div> <FormGroup ><Col componentClass={ControlLabel}  sm={3} smOffset={1}  >Tag</Col>  <Col  sm={8} ><div className="addresstag">{this.state.tag}</div></Col></FormGroup>
       <FormGroup > <Col  componentClass={ControlLabel} sm={3} smOffset={1}  ></Col>  <Col  sm={8} > <CopyToClipboard text={this.state.tag}
          onCopy={() => this.setState({copiedtag: true,copied:false})}>
          <a >{this.state.copiedtag ? <span  style={{color: 'green'}}>Tag has been copied</span> : <span className="btn">Copy Tag</span>}</a>
        </CopyToClipboard>
         </Col></FormGroup></div>:''}  </Form></div> }
       
       </Col></Row></Grid></Modal.Body>
         <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showAddressModal: false },this.getaddresses())}
                        >Close
                        </Button>
                      
                      </Modal.Footer></div>}

                      
                      </Modal>


      </div>









    );
  }
}

export default Addresses;