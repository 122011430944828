import React, { Component } from "react";

class Loader extends Component {
    render(){
  return (
    <div className="loaderHolder" >
    <div className="loader center" >
      <i className="fa  fa-circle-o-notch fa-spin"  aria-hidden="true"/>
    </div> </div>
  );
}
}
export default Loader;
