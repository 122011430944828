import React, { Component } from "react";

class Loading extends Component {
    render(){
  return (
 
    <div className="loader center" >
      <i className="fa  fa-circle-o-notch fa-spin"  aria-hidden="true"/>
    </div> 
  );
}
}
export default Loading;
