import React, { Component } from "react";
import { Grid,  Row } from "react-bootstrap";






class Dashboard extends Component {

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
         

   
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
