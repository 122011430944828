import React, { Component } from "react";
import {

  Nav,

  NavDropdown,
  MenuItem


} from "react-bootstrap";

class HeaderLinks extends Component {


  render() {
    var user=JSON.parse(localStorage.getItem('user'));
    return (
      <div>
       
        <Nav pullRight>
     
      
         
          <NavDropdown
            eventKey={1}
            title={
              <div>
              <i className="fad fa-user-circle"></i>
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
           <li className="namedisplay" ><span>{user.data.firstname} {user.data.lastname}</span> </li>
           <MenuItem eventKey={1.2} href="/#/profile" >
           
           <div className="navbtn" >
          Profile
           </div>
         </MenuItem>
            <MenuItem eventKey={1.1} href="/#/a/login-page" >
           
              <div className="navbtn" >
               Sign out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;
