let Config = {
  API:'https://payapi.crypto-suites.com',
 //API:'http://localhost:5000',
  MinInvoice:25,
  gtag:'AW-00000000',
  Company:'CryptoSuites',
  Website:'https://crypto-suites.com',
  WidgetUrl:'https://apps.crypto-suites.com/widget/cryptosuites.js?v4',
  WidgetName:'cryptosuites',
  VerifyUrl:'https://4726btyathy.typeform.com/CoinSuitesVER1',
  POSUrl:'https://pos.crypto-suites.com/',
  SignupUrl:'',
  wordpress:'',
  ShowSignup:false
};
module.exports = Config;