import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Select from "react-select";
const Config = require('config');


class Paynow extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
 description:"",
 pos_url:"",
 pos_currency:'USD',
 pos_show_currency:0,
showval:false,
 pos_urlError: null,
pos_weburl: Config.POSUrl


      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleshowChange = this.handleshowChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);

 
  }
  componentDidMount(){

    this.getposinfo();


  }

  getposinfo(){

    var user=JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/pos/',requestOptions)
    .then( this.handleResponse)
     .then( pos=> this.setState({
        pos_url:pos.data.pos_url,
        pos_currency:pos.data.pos_currency,
        pos_show_currency:pos.data.pos_show_currency,
         loading: false},()=>{
          if(this.state.pos_show_currency==1){this.setState({showval:true})}else{this.setState({showval:false})}

         }));

     
  
    }


    updateposinfo(pos_url,pos_currency,pos_show_currency){

      var user=JSON.parse(localStorage.getItem('user'));
      var mid=user.data.merchant_id;
   
      var token=user.token;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','access-token': token,'mid':user.data.merchant_id},
         body: JSON.stringify({ pos_currency,pos_url,pos_show_currency}
          )
      };
          
    
  return fetch(Config.API+`/api/pos/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","POS info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    
  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          if(data.status=='error'){ 
          localStorage.removeItem('user');
         window.location.reload(true);
            return 'error'
           
          }

        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }

  handleCurrencySelectChange = (data)=> {
    if(data!==null){
    this.setState({ pos_currency:data.value});}
   
  };
  

  

  handleshowChange(){
    this.state.showval==true
    ? this.setState({showval:false,pos_show_currency:0})
 : this.setState({showval:true,pos_show_currency:1})
    
  }


  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
    

      if(this.state.pos_url!=='')
    
      { if(!this.state.pos_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
        pos_urlError: (
      <small className="text-danger">Please enter a valid URL</small>
      )
      })}else{this.setState({  pos_urlError: null })}
     
    }else{this.setState({  pos_urlError: null })}
  
    
          


  }

  handleSubmit(e) {
    e.preventDefault();
    const { pos_url,pos_currency,pos_show_currency} = this.state;
   if(  this.state.pos_urlError  === null ){

  this.updateposinfo(pos_url,pos_currency,pos_show_currency)}

   }



  render() {
    const { pos_currency,pos_url,pos_show_currency,loading,showval} = this.state;
    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'},{ value: 'GBP', label: 'GBP'}];

    if (loading) return <Loader />
   return (   <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
          <Col lg={12}>  <h3>POS Settings</h3> </Col>
       <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
        
          </Breadcrumb>



  
         

                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                
                  content={
                    <div>
                     

                


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="pos_url"
                          value={pos_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.pos_urlError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Default Currency
                        </Col>
                        <Col sm={6}>
                          
<Select
                          isClearable={false}
                      
                          name="pos_currency"
                          value={pos_currency}
                          options={CurrencyOptions}
                          onChange={this.handleCurrencySelectChange}
                        />
                


                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                 Display Currency Selector 
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={showval}
                        id="1"
                   
                        onChange={this.handleshowChange}
                      />
                 
      </div>
                 


                        </Col>
                      </FormGroup>
              

                   
                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                     Update
                    </Button>
                  }
                />
              </Form>
            </Col>
 
            <Col md={12}> 
              <Form horizontal >
                <Card
            
                  content={
                    <div>
                      
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                  </Col>
                        <Col sm={6}>       POS URL <a href={this.state.pos_weburl} target="_blank">{this.state.pos_weburl}</a>
 </Col>
                      </FormGroup>
                     
                         
                   
                    
                    </div>
                  }
      
               
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Paynow ;
