

const Config = require('config');
export const userForgot = {
 
    forgot

};

function forgot(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username})
    };

    return fetch(Config.API+`/passwordreset`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
              
            }

            return user;
        });
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //console.log(data);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
           
              window.location.reload(true);
            }

         // const error = (data && data.message);
          return Promise.reject('Sorry we cant find an account associated with address');
        }

        return data;
    });
}