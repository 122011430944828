import React, { Component } from "react";
import {
 Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

import Login from "components/Login/Reset.jsx";
import { userReset } from 'helpers/user.reset.jsx';


class   ResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardHidden: true,
      password: '',
      submitted: false,
      reset: false,
      passwordreset:'',
      error: '',
   
      new_passwordError:null,
      new_password2Error:null,
     
      new_password:"",
      new_password2:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
this.handleValidate = this.handleValidate.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}


handleValidate(e) {

    
  this.state.new_password.length< 8
? this.setState({
new_passwordError: (
<small className="text-danger">Password must be at least 8 characters long</small>
)
})
: this.state.new_password.search(/\d/) == -1
? this.setState({
new_passwordError: (
   <small className="text-danger">Password must contain 1 number</small>
 )
   })
   : this.state.new_password.search(/[a-zA-Z]/) == -1
? this.setState({
new_passwordError: (
   <small className="text-danger">Password must contain 1 letter</small>
 )
   })
  : this.setState({ new_passwordError: null });

  
  this.state.new_password2==""
  ? this.setState({
   new_password2Error: (
       <small className="text-danger">Please confirm your password.</small>
     )
       })
  :this.state.new_password2.length< 8
  ? this.setState({
    new_password2Error: (
        <small className="text-danger">Password Must be at least 8 characters long</small>
      )
        })
        : this.state.new_password2.search(/\d/) == -1
        ? this.setState({
          new_password2Error: (
              <small className="text-danger">Password must contain 1 number</small>
            )
              })
              : this.state.new_password2.search(/[a-zA-Z]/) == -1
        ? this.setState({
          new_password2Error: (
              <small className="text-danger">Password must contain 1 letter</small>
            )
              })
              : this.state.new_password != this.state.new_password2
              ? this.setState({
                new_password2Error: (
                    <small className="text-danger">Password must match</small>
                  )
                    })
             : this.setState({ new_password2Error: null });


                  return
}


async  handleSubmit(e) {

  await this.handleValidate();
  
  this.handleUpdate() 


  }


handleUpdate() {

  if(this.state.new_passwordError === null && this.state.new_password2Error === null){
  
    const { new_password} = this.state;
 const userid=this.props.match.params.userid;
 const token=this.props.match.params.token;
    // stop here if form is invalid
    if (!(new_password)) {
        return;
    }

    this.setState({ loading: true });
    userReset.reset(new_password,userid,token)
        .then(
            user => {
              this.setState({loading: false,new_password2:'',new_password:'',passwordreset:<div>Your password has been updated<br/>Please <a href="/#/a/login-page">Sign in</a></div>})

             // const { from } = this.props.location.state || { from: { pathname: "/" } };
               //this.props.history.push(from);
            //  window.location.href ="/#/dashboard";
            },
            error => this.setState({ error, loading: false })
        );
}
}
  componentDidMount() {

 
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  render() {  
    const {  loading, error,new_password,new_password2,passwordreset } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
        
              <Login
                hidden={this.state.cardHidden}
                textCenter
                content={   
                  <div><div className="formTop">
                  <h2>Reset Your Password</h2>

                  {passwordreset &&
                  <div >{passwordreset}</div>
              }
             
                  </div>  
                  {!passwordreset &&   <FormGroup controlId="formHorizontalRequiredText">
                        <div componentClass={ControlLabel} sm={12} >
                        New Password
                        </div>
                        <div sm={12}>
                        <FormControl
                            type="password"
                            name="new_password"
                          value={new_password}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.new_passwordError}
                        </div>
            </FormGroup> }
            {!passwordreset &&        <FormGroup controlId="formHorizontalRequiredText">
                        <div componentClass={ControlLabel} sm={12} >
                        Re Enter New Password
                        </div>
                        <div sm={12}>
                        <FormControl
                            type="password"
                            name="new_password2"
                            value={new_password2}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.new_password2Error}
                        </div>
                      </FormGroup>}
             
                      {!passwordreset &&   <div className="form-group">
                  <button className="btn btn-primary" disabled={false}  onClick={() => this.handleSubmit()}>Reset</button>
                  {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
              </div>}
              {error &&
                  <div className={'alert alert-danger'}>{error}</div>
              }
                  </div> 
                }
              
                ftTextCenter
              /> 
          
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ResetPage;
