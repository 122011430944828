import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table-6";

import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import 'react-table-6/react-table.css'
const Config = require('config');


class Users extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                merchantaccountlist:[]
                  
                }
               
                //console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.getusers();

  }
  
  getusers(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token,'mid':mid},
   };
      


    fetch(Config.API+'/api/users',requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ merchantaccountlist:transactiondata.data,loading:false}));
   

  }

  newuser(){ window.location.hash = "#/users/new"};

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

  render() {
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
          row[id] !== undefined ?
          isNaN(row[id])
            ?  String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            : String(row[id]).startsWith(filter.value)
          :
              true
      );
    }
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>   <h3 className="header">Users</h3></Col>
          
<Col lg={12}>

          <div className="card">
      
             

          <div className="content">
         <Button  bsStyle="default" onClick={() => this.newuser()}>
                      New
                        <span className="btn-label btn-label-right">
                        <i class="fas fa-user-circle" />
                        </span>
                      </Button>
 

      
                  <ReactTable
                  
                    data={this.state.merchantaccountlist}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                    columns={[
                      {
                        Header: "User Name",
                        accessor:"username",
                       
                       
                      },
                      {
                        Header: "First Name",
                        accessor:"firstname",
                       
                       
                      },

                      {
                        Header: "Last Name",
                        accessor:"lastname",
                       
                       
                      },
                      
                     
                      {
                        Header: "Actions",
                        width: 200,
                        Cell: row => (
                          <div className="actions-right">
 
       <Button onClick={() => window.location.hash = "#/users/"+row.original.user_id}
    bsStyle="info"
    simple
    icon
    ><i className="fa fa-edit" /></Button>{" "}
                          </div>
                      ),
                        resizable: true,
                        filterable: false,
                        sortable: false,
                      }
                      
                    ]}
                    defaultPageSize={10}
              
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                

               
          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Users;