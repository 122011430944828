

const Config = require('config');
export const userReset = {
 
    reset

};

function reset(password,userid,token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid': userid},
        body: JSON.stringify({password})
    };

    return fetch(Config.API+`/api/updatepasswordreset`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
              
            }

            return user;
        });
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
    
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
           
              window.location.reload(true);
            }

         // const error = (data && data.message);
          return Promise.reject('Your password was not updated');
        }
        if(data.status=='error'){return Promise.reject('The reset link has expired');}

        return data;
    });
}